.navigation-toggle {
    padding: 1em;
    position: fixed;
    top: 0;
    left: 0;   
    z-index: 2;
    height: 60px;
}

ul.navigation {
    display: flex;
    list-style: none;
    flex-direction: column;
    justify-content: center;
   
}

.navigation-item {
    padding: 1em;
}

.navigation-item.navigation-brand {
    padding: 0;
}

.navigation-brand {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navigation-brand > img {
    height: 3em;
    width: 3em;
}

.navigation-item > a {
    text-decoration: none;
}


.navbar {
    position: fixed;
    top: 79px;
    z-index: 1;
}


@media (max-width: 991px) {

    nav.navigation {
        width: 100vw;
    }

    .navbar, .navigation-toggle {
        width: 100vw;
    }

    ul.navigation {
        align-items: center;
        padding-bottom: 10px;
    }

    li.navigation-item a {
        font-size: large;
    }

    .navbar.hidden {
        top: -100vh;
        animation-name: example;
        animation-duration: 4s;
        transition:all .75s ease;
    }
    
    .navbar.visible {
        top: 79px;
        animation-name: example2;
        animation-duration: 4s;
        transition:all .75s ease;
    }
    
}

@media (min-width: 992px) {
    nav.navigation {
        width: 150px;
    }

    ul.navigation {
       align-items: flex-start;
   
    }

    li.navigation-item a {
        font-size: x-large;
        padding-left: 20px;
    }

    .navbar.hidden {
        left: -300px;
        animation-name: example;
        animation-duration: 4s;
        transition:all .75s ease;
    }
    
    .navbar.visible {
        left: 0;
        animation-name: example2;
        animation-duration: 4s;
        transition:all .75s ease;
    }
    
}

