.nav-outlet-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    vertical-align: center;
}

@media (max-width: 991px) {
    .nav-outlet-wrapper {
        padding-top: 80px;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

@media (min-width: 992px) {

    .nav-outlet-wrapper {
        padding-right: 100px;
        padding-top: 20px;
    }

    .nav-outlet-wrapper.visible {
        padding-left: 300px;
        animation-name: example3;
        animation-duration: 4s;
        transition: all .75s ease;
    }

    .nav-outlet-wrapper.hidden {
        padding-left: 100px;
        animation-name: example4;
        animation-duration: 4s;
        transition: all .75s ease;
    }

}