.page-footer {
    min-height: 200px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 1rem;
}

.stack-right {
    display: flex;
    justify-content: flex-end;
}

.text-box {
    display: inline-block;
    line-height: 3em; 
    height: 3em;
    padding: 0 1.5em;
    min-width: 5em;
    text-align: center;
    text-indent: 0;
}

.footnote {
    font-size: x-small;
}