.contact-container {
    min-height: 100;
}

ul.contact {
    list-style-type: none;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

ul.contact>li {
    margin: 20px;
    padding: 0;
}

.social-media-icon {
    display: block;
    font-size: 100px;
    color: #000;
    margin: 0 auto;
}

li.profile-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-width: 200px;
}

a:has(i)  {
    text-decoration: none;
    
}

.profile-link:hover > a > * {
    color: #8bd3cb;
}

.profile-link:hover > a > i {
    background-color: #8bd3cb;
    border-color: #8bd3cb;
    color: black;
}


i.link-icon {
    font-size: 72px;
    width: 1.5em;
    text-align: center;
    display: inline-block;
    text-indent: 0.1em;
    color: black;
    background-color: white;
    border-style: solid;
    border-width: 15px;
    border-radius: 15px;
    border-color: white;
}

.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    height: 100%;
}

h1.contact {
    flex-grow: 1;
}

section.contact {
    flex-grow: 1;
}

main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}