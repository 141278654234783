* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  letter-spacing: .1em;
  background-color: black;
  color: white;
}

h1 {
  font-weight: normal;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

p  {
  text-indent: 30px;
  margin-bottom: 0;
}

p, p > * {
  line-height: 2em;
  padding: 10px 0;
}

a {
  text-decoration-skip: spaces;
  text-decoration-skip-ink: all;
  text-underline-position: under;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

section {
  padding: 20px;
}

