section > h2 {
    margin-bottom: 20px;
    margin-top: 30px;
    /* font-weight: normal; */
}

ul.portfolio {
    list-style-type: none;
}

ul.portfolio > li {
    padding-bottom: 20px;
    padding-top: 20px;
}

header.portfolio-item {
    display: flex;
    align-items: center;
    height: 50px;
}

img.portfolio-icon {
    width: 3em;
    height: 3em;
}

@media (max-width: 991px) {
   header.portfolio-item h3 {
        text-indent: 10px;
        margin-left: 10px;
    }
}

@media (min-width: 992px) {
    header.portfolio-item h3 {
        text-indent: 30px;
        margin-left: 15px;
    }
}